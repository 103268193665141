<!--
 * @Description: 发布需求页面
 * @Author: zhang zhen
 * @Date: 2023-02-06 09:49:41
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-03-16 11:56:51
 * @FilePath: /page-sass/src/views/PublishingRequirements/index.vue
-->
<template>
  <div class="PublishingRequirements">
    <div class="info-msg" v-if="stepKey == 'RequirementConfirmation'">
      <img src="~@/assets/warning.png" alt="">
      <span>需求单已生成，请确认</span>
    </div>
    <!-- <div class="PublishingRequirements-cover">
      <a-steps :current="stepKey == 'CompleteInformation' ? 0 : stepKey == 'RequirementConfirmation' ? 1 : 2" size="small">
        <a-step title="完善详细要求" description="提示文字" />
        <a-step title="确认需求内容" description="提示文字" />
        <a-step title="查看推荐" description="提示文字" />
      </a-steps>
    </div> -->
    <div class="step-cover" v-if="stepKey == 'CompleteInformation'">
      <a-steps :current="0" progress-dot>
        <a-step title="发布需求" />
        <a-step title="推荐供应商" />
        <a-step title="供应商参与" />
        <a-step title="咨询议价/确定供应商" />
      </a-steps>
    </div>
    <div class="PublishingRequirements-content" v-if="stepKey == 'CompleteInformation'" >
      <CompleteInformation ref="CompleteInformation" :formType="formType"
        @ok="handleStepOk" @changePurchaseId="handleSavePurchaseId" :purchaseId="purchaseId" />
    </div>
    <RequirementConfirmation ref="RequirementConfirmation" v-else :formType="formType" :purchaseId="purchaseId"
        @handleEmitEdit="handleChangeStep" />
    <Elevator />
  </div>
</template>

<script>
import Elevator from '@/components/tools/elevator.vue'
import CompleteInformation from './modules/completeInformation.vue'
import RequirementConfirmation from './modules/RequirementConfirmation.vue'

export default {
  name: 'PublishingRequirements',
  components: {
    Elevator,
    CompleteInformation,
    RequirementConfirmation
  },
  data() {
    return {
      stepKey: 'CompleteInformation',
      formType: 'product',
      purchaseId: '',
      isEdit: false
    }
  },
  created() {
    const { formType, purchaseId, stepKey } = this.$route.query
    formType && (this.formType = formType)
    purchaseId && (this.purchaseId = purchaseId)
    stepKey && this.handleChangeStep({ key: 'CompleteInformation' })
  },
  methods: {
    handleStepOk(e) {
      this.stepKey = e
      if (this.isEdit) {
        this.$nextTick(_ => {
          this.$refs[e]['handleInitForm']()
        })
      }
    },
    handleChangeStep({ key }) {
      this.isEdit = true
      this.handleStepOk(key)
    },
    handleSavePurchaseId(e) {
      this.purchaseId = e
    }
  }
}
</script>

<style lang="less" scoped>
.PublishingRequirements {
  padding: 30px 0 64px;
  .flexLayout(@direction: column; @justifyContent: flex-start; );
  background-color: rgb(246, 247, 249);

  &-steps {
    list-style: none;
    margin-bottom: 30px;
    .flexLayout(@justifyContent: flex-start; );

    &-item {
      width: 175px;
      height: 37px;
      line-height: 37px;
      text-align: center;
      color: #bcbcbc;
      background: #f2f2f2;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        background: #f2f2f2;
        width: 26px;
        height: 26px;
        transform: rotate(45deg);
        left: -13px;
        top: 5px;
        display: none;
      }

      &::after {
        position: absolute;
        content: '';
        background: #f2f2f2;
        width: 27px;
        height: 27px;
        transform: rotate(45deg);
        border-top: 1px solid #e3e5ee;
        border-right: 1px solid #e3e5ee;
        top: 5px;
        right: -13px;
        z-index: 10;
      }

      &:first-child {
        &::before {
          background: #e3e5ee;
          display: block;
        }
      }

      // #e3e5ee
      &.active {
        color: #fff;
        background: #0099ff;

        &::after {
          background: #0099ff;
          border: none;
          width: 26px;
          height: 26px;
        }
      }
    }
  }

  &-cover {
    // max-width: 1184px;
    // width: calc(100vw - 280px);
    width: 1288px;
    max-width: 1288px;
    padding: 20px 32px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 25px;

    ::v-deep .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
      color: #FF6E2D !important;
    }

    ::v-deep .ant-steps-item-icon {
      margin-right: 16px;
    }

    ::v-deep .ant-steps-small .ant-steps-item-title {
      font-size: 16px;
      padding-right: 16px;
      color: #131212;
    }
  }

  &-content {
    // max-width: 1184px;
    // width: calc(100vw - 280px);
    width: 1184px;
    max-width: 1184px;
    padding: 15px;
    box-sizing: border-box;
    padding: 24px 32px 64px;
    background: #ffffff;
    border-radius: 8px;
  }

  .info-msg {
    // max-width: 1184px;
    // width: calc(100vw - 280px);
    width: 1184px;
    max-width: 1184px;
    height: 40px;
    padding: 9px 25px;
    box-sizing: border-box;
    background: rgba(255, 125, 47, 0.1);
    border-radius: 4px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    color: #000;

    img {
      width: 17px;
      margin-right: 9px;
    }
  }
}


.step-cover {
  margin-top: 32px;
  width: 1184px;
  padding: 0 0 22px;
  display: flex;
  justify-content: flex-end;

  ::v-deep .ant-steps {
    width: max-content;
  }
  ::v-deep .ant-steps-item-title {
    color: #8D8E99!important;
  }
  ::v-deep .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #131212!important;
  }
  ::v-deep .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #FF6E2D;
    border: none;
  }
  ::v-deep .ant-steps-dot .ant-steps-item-icon {
    width: 10px;
    height: 10px;
    .ant-steps-icon-dot {
      background: #fff;
      border: 2px solid #8D8E99;
    }
  }
  ::v-deep .ant-steps-item-title {
    font-size: 12px;
  }
  ::v-deep .ant-steps-item-content {
    position: absolute;
    top: -35px;
  }
  ::v-deep .ant-steps-label-vertical .ant-steps-item {
    width: 110px;
  }
}
</style>
