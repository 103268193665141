<!--
 * @Description: 字典组件
 * @Author: zhang zhen
 * @Date: 2023-04-02 17:20:54
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-04-22 17:11:17
 * @FilePath: /page-sass/src/components/plugins/JDictSelect.vue
-->
<template>
  <div>
    <a-select :placeholder="placeholder" :size="size" :disabled="disabled" :value="getValueSting" @change="handleChange" allowClear v-if="optionType == 'select'">
      <!-- suffixIcon -->
      <img src="/Polygon.png" alt="" class="icon-arrow-tag" slot="suffixIcon">
      <a-select-option :value="undefined">请选择</a-select-option>
      <a-select-option v-for="(item, key) in dictOptions" :key="key" :value="item.wrapType">
        <span style="display: inline-block; width: 100%" :title="item.wrapTypeDictName">
          {{ item.wrapTypeDictName }}
        </span>
      </a-select-option>
    </a-select>
    <a-radio-group name="radioGroup" :value="getValueSting" @change="handleChangeRadio" :disabled="disabled" v-else>
      <a-radio :value="item.dictKey" v-for="(item, key) in dictOptions" :key="key"> {{ item.dictValue }} </a-radio>
    </a-radio-group>
  </div>
</template>
<!-- :getPopupContainer="getPopupContainer" -->
<script>
import { postAction } from '@/api/manage'
export default {
  name: 'JDictSelectTag',
  props: {
    dictCode: String,
    placeholder: String,
    triggerChange: Boolean,
    disabled: Boolean,
    value: [String, Number],
    type: String,
    getPopupContainer: {
      type: Function,
      default: (node) => node.parentNode,
    },
    size: {
      type: String,
      default: 'default'
    },
    optionType: {
      type: String,
      default: 'select'
    }
  },
  data() {
    return {
      dictOptions: [],
    }
  },
  watch: {
    dictCode: {
      immediate: true,
      handler() {
        this.initDictData()
      },
    },
  },
  computed: {
    getValueSting() {
      return this.value ? this.value : undefined
    },
  },
  methods: {
    initDictData() {
      // 优先从缓存中读取字典配置
      // 根据字典Code, 初始化字典数组
      postAction('/purchases/queryWrapTypeByPurchaseType', { purchaseType: this.dictCode, wrapTypes: [] }).then(res => {
        if (res.success) {
          this.dictOptions = res.data
        }
      })
    },
    handleChange(value) {
      let record = this.dictOptions.find((res) => res.wrapType == value)
      this.$emit('change', value)
      this.$emit('input', value)
      this.$emit('backChange', {
        value: value,
        label: record.wrapTypeDictName
      })
    },
    handleChangeRadio(e) {
      let value = e.target.value
      this.$emit('change', value)
      this.$emit('input', value)
    }
  },
  model: {
    prop: 'value',
    event: 'change',
  },
}
</script>

<style scoped></style>
